html.no-overflow {
  overflow: hidden;
}
header {
  position: relative;
  z-index: 2;
  margin: 0rem;
  padding: 0 1rem;
  height: 66px;
  align-items: center;
  display: flex;
  background-image: url("../../images/header-bg.png");
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 0;
    background: rgba(89, 7, 7, 0.16);
    mix-blend-mode: multiply;
  }
  .cart-wrp{
    position: relative;
  }
  .cart-circle{
    position: absolute;
    top: -4px;
    left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border-radius: 100%;
    font-size: 11px;
    font-weight: 700;
    color: #222;
    border: 1px solid #d82027;
  }
  .header-block {
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: relative;
    z-index: 1;
    .brand-logo {
      img {
        height: 3rem;
        width: auto;
      }
    }
    .header-menu {
      list-style: none;
      position: relative;
      z-index: 1;
      display: flex;
      gap: 1rem;
      padding: 0;
      margin: 0;
      align-items: center;
      @media screen and (max-width: 767px) {
        gap: 0.3rem;
      }
      &.main-menus {
        @media screen and (max-width: 991px) {
          position: fixed;
          right: 0;
          top: 0;
          bottom: 0;
          background: #db191c;
          height: 100%;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 20rem;
          padding-top: 3rem;
          z-index: 9;
          transform: translateX(100%);
          transition: all 300ms ease;
          align-items: flex-start;
          &.mobile-open {
            transform: translateX(0);
          }
        }
        .close-btn {
          display: none;
          @media screen and (max-width: 991px) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            color: #ffffff;
            padding: 0.5rem;
            font-size: 1.5rem;
          }
        }
      }

      li {
        
        padding: 1rem;
        &.dropdown-wrp {
          &:hover {
            .hd-dropdown{
              ul {
                height: auto;
              }
            }
          }
          .hd-dropdown{
            
            ul {
              display: flex;
              flex-direction: column;
              background: #fff;
              border-radius: 6px;
              width: 140px;
              position: absolute;
              top: 50px;
              left: 0;
              height: 0;
              overflow: hidden;
              li{
                padding: 0;
                a {
                  color: #ff2525;
                  padding: 6px 10px;
                  display: block;
                  border-bottom: 1px solid #ff2525;
                }
              }
            }
          }
        }
        
        a {
          color: #ffffff;
          text-decoration: none;
          font-weight: 500;
          font-size: 1.2rem;
        }

        &.menu-icon {
          display: none;
          @media screen and (max-width: 991px) {
            display: block;
            color: #ffffff;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
