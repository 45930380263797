// Initial popup design start
.init-popup-top {
  display: none;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: absolute;
  width: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;

  &.open {
    display: flex;
  }
}
.init-popup-wrp {
  img {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto -92px;
    z-index: 9;
    position: relative;
  }
  position: relative;
  padding: 0 15px;
  .init-popup-in {
    background: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(255, 89, 89, 1) 100%);
    border-radius: 38px;
    padding: 120px 15px 15px;
    margin: 0 auto;
    z-index: 1;
    max-width: 824px;
    overflow: hidden;
    position: relative;
    span {
      color: #FFC2C2;
      font-size: 203px;
      font-weight: 900;
      line-height: 1;
      position: absolute;
      left: 0;
      right: 0;
      top: -90px;
      opacity: 0.3;
    }
    p {
      font-size: 17px;
      line-height: 1.35;
      color: #fff;
      font-family: 'Poppins';
      font-weight: 200;
      text-transform: capitalize;
      margin-bottom: 1.5rem;
    }
    button {
      background: linear-gradient(90deg, rgba(255, 237, 237, 1) 0%, rgba(255, 178, 178, 1) 100%);
      border: 0;
      border-radius: 12px;
      max-width: 300px;
      width: 100%;
      height: 52px;
      font-size: 19px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      transition: 0.3s ease-in all;

      &:hover {
        background: linear-gradient(90deg, rgba(255, 178, 178, 1) 0%, rgba(255, 237, 237, 1) 100%);
        transition: 0.3s ease-in all;
      }
    }
  }
}

// Loading design
.loading-wrp{
  background: linear-gradient(90deg, rgb(255, 237, 237) 0%, rgb(255, 237, 237) 100%);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  img {

  }
}

@media (max-width: 767px) {
  .init-popup-wrp {
    .init-popup-in {
      span {
        font-size: 23.2vw;
        top: -8.2vw;
      }

      p {
        font-size: 15px;
        margin-bottom: 20px;
      }

      button {
        max-width: 240px;
        height: 40px;
        font-size: 16px;
      }
    }

  }
}