.loginpage {
    background-image: url("../../images/Login-bg.png");
  
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
  
    @media screen and (max-width: 991px) {
      background-position: left top;
      padding: 0 1.5rem;
    }
  
    article {
      padding: 2rem;
      max-width: 32rem;
      border-radius: 1.5rem;
      margin: auto;
      background: #fff;
      width: 100%;
      box-shadow: 0px 15px 21.6px 0px #00000040;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
  
      img {
        max-width: 170px;
      }
  
      h4 {
        color: var(--text-primary, #101012);
        font-family: Poppins;
        font-size: 2.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        /* 28px */
        text-align: center;
  
        @media screen and (max-width: 991px) {
          font-size: 1.8rem;
        }
      }
    }
  
    .login-btn {
      border-radius: 0.5rem;
      background: #f00;
      color: #ffffff;
      display: flex;
      padding: 0.8rem 2rem;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      outline: 0 !important;
      border: none !important;
      box-shadow: none !important;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;  
      width: 100%;
  
      &:hover,
      &:focus,
      &:active {
        background: #f50707;
        color: #ffffff;
      }
    }
  }